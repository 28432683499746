<script>
    import BaseClass from "@/base";
    import { Component, Mixins } from "vue-property-decorator";
    // import { Candlestick, mixins } from "vue-chartjs-financial";
    import { Bar, mixins } from "vue-chartjs";
    const { reactiveProp } = mixins;

    @Component({
        name: "BarChart",
        // mixins: [reactiveProp],
        // props: {
        //     chartData: {
        //         type: Object,
        //         default: null,
        //     },
        //     options: {
        //         type: Object,
        //         default: null,
        //     },
        // },
        // components: { Line },
        mixins: [reactiveProp],
        props: {
            chartData: {
                type: Object,
                default: null,
            },
            options: {
                type: Object,
                default: null,
            },
        },
    })
    export default class BarChartComponent extends Mixins() {
        mounted() {
            this.renderChart(this.chartData, this.options);
        }
    }
</script>
