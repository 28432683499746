<script>
    import { Pie } from "vue-chartjs";

    export default {
        extends: Pie,
        mounted() {
            this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient2 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient3 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient4 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient5 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient6 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient7 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

            this.gradient.addColorStop(0, "rgba(11, 74, 132)");
            // this.gradient.addColorStop(0.5, "rgba(11, 74, 132, 0.25)");
            // this.gradient.addColorStop(1, "rgba(11, 74, 132, 0)");

            this.gradient2.addColorStop(0, "rgba(92, 157, 217)");

            this.gradient3.addColorStop(0, "rgba(72, 132, 188)");

            this.gradient4.addColorStop(0, "rgba(52, 103, 150)");

            this.gradient5.addColorStop(0, "rgba(17, 66, 113)");

            this.gradient6.addColorStop(0, "rgba(11, 74, 132)");

            this.gradient7.addColorStop(0, "rgba(11, 74, 132)");

            this.renderChart(
                {
                    labels: ["North America", "South America", "Australia", "Asia", "Africa", "Antartica", "Europe"],
                    datasets: [
                        {
                            backgroundColor: [
                                this.gradient,
                                this.gradient2,
                                this.gradient3,
                                this.gradient4,
                                this.gradient5,
                                this.gradient6,
                                this.gradient7,
                            ],
                            data: [20, 15, 10, 10, 10, 15, 20],
                        },
                    ],
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                }
            );
        },
    };
</script>
