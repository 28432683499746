var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"search",on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('div',{staticClass:"table-filter-wrapper"},[_c('div',{staticClass:"search-bar-wrapper"},[_c('div',{staticClass:"icon-wrapper"},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.agentName),expression:"filters.agentName"}],staticClass:"search-bar",attrs:{"placeholder":"Search agent name","clearable":""},domProps:{"value":(_vm.filters.agentName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "agentName", $event.target.value)}}})]),(this.$account.isFullAdmin || this.$account.permissions.canSearchLongPageSizing50100 === true)?_c('div',{staticClass:"view-per-page-wrapper"},[_c('p',{staticClass:"mr-2"},[_vm._v("View")]),_c('div',{staticClass:"view-per-page"},[_c('v-select',{staticClass:"view-per-page-dropdown rounded-xl",attrs:{"rounded":"","filled":"","solo":"","medium":"","dense":"","hide-details":"","items":[50, 100]},model:{value:(_vm.filters.itemsPerPage),callback:function ($$v) {_vm.$set(_vm.filters, "itemsPerPage", $$v)},expression:"filters.itemsPerPage"}})],1),_c('div',{staticClass:"per-page"},[_c('p',[_vm._v("per page")])])]):_vm._e(),_c('div',{staticClass:"date-range-wrapper"},[_c('div',{staticClass:"from-wrapper mr-2"},[_c('p',[_vm._v("From")]),_c('v-menu',{ref:"dateFrom",attrs:{"close-on-content-click":false,"return-value":_vm.filters.dateFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "dateFrom", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "dateFrom", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"rounded":"","filled":"","solo":"","dense":"","hide-details":"","label":"Select Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filters.dateFrom),callback:function ($$v) {_vm.$set(_vm.filters, "dateFrom", $$v)},expression:"filters.dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromMenu),callback:function ($$v) {_vm.dateFromMenu=$$v},expression:"dateFromMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filters.dateFrom),callback:function ($$v) {_vm.$set(_vm.filters, "dateFrom", $$v)},expression:"filters.dateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateFromMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateFrom.save(_vm.filters.dateFrom)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"to-wrapper mr-2"},[_c('p',[_vm._v("To")]),_c('v-menu',{ref:"dateToMenu",attrs:{"close-on-content-click":false,"return-value":_vm.filters.dateTo,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filters, "dateTo", $event)},"update:return-value":function($event){return _vm.$set(_vm.filters, "dateTo", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"rounded":"","filled":"","solo":"","dense":"","hide-details":"","label":"Select Date","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.filters.dateTo),callback:function ($$v) {_vm.$set(_vm.filters, "dateTo", $$v)},expression:"filters.dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToMenu),callback:function ($$v) {_vm.dateToMenu=$$v},expression:"dateToMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filters.dateTo),callback:function ($$v) {_vm.$set(_vm.filters, "dateTo", $$v)},expression:"filters.dateTo"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateToMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dateToMenu.save(_vm.filters.dateTo)}}},[_vm._v(" OK ")])],1)],1)],1),_c('div',{staticClass:"actions-wrapper"},[_c('button',{staticClass:"action-clear",attrs:{"type":"button"},on:{"click":_vm.clearForm}},[_vm._v("Clear")]),_c('button',{staticClass:"action-submit",attrs:{"type":"submit"}},[_vm._v("Submit")])])])]),_c('div',{staticClass:"advance-filter-wrapper"},[(this.$account.isFullAdmin || this.$account.permissions.canExportToExcel === true)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"export-button",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,3062317285)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/reports-by-agent"}},[_c('v-list-item-title',[_vm._v("Export to Excel")])],1)],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }