<script>
    import firebase from "@/plugins/firebase";
    import { Doughnut } from "vue-chartjs";

    export default {
        extends: Doughnut,
        async mounted() {
            try {
                // this.loading = true;
                // get stats
                const statsRef = await firebase
                    .firestore()
                    .doc(`private/stats`)
                    .get();
                if (!statsRef.exists) {
                    throw new Error("Failed to retrieve stats.");
                }

                // TODO: check total registration if similar to userCount
                // this.dashboardTotalRegistration = statsRef.data()?.dashboardTotalRegistration;

                this.dashboardTotalRegistration = statsRef.data()?.userCount;
                this.dashboardTotalFTD = statsRef.data()?.dashboardTotalFTD;

                this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
                this.gradient2 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

                this.gradient.addColorStop(0, "rgba(17, 66, 113)");

                this.gradient2.addColorStop(0, "rgba(54, 115, 172)");
                // get center percentage
                const centerPercentage = (this.dashboardTotalFTD / this.dashboardTotalRegistration) * 100;

                this.addPlugin({
                    id: "my-plugin",
                    beforeDraw: function(chart) {
                        const width = chart.chart.width;
                        const height = chart.chart.height;
                        const ctx = chart.chart.ctx;

                        ctx.restore();
                        const fontSize = (height / 140).toFixed(2);
                        ctx.font = fontSize + "em sans-serif";
                        ctx.textBaseline = "middle";

                        const text = `${centerPercentage.toFixed(2)}%`;
                        const textX = Math.round((width - ctx.measureText(text).width) / 2);
                        const textY = height / 2;

                        ctx.fillText(text, textX, textY);
                        ctx.save();
                    },
                });

                this.renderChart(
                    {
                        labels: ["Total Registration", "Total FTD"],
                        datasets: [
                            {
                                backgroundColor: [this.gradient, this.gradient2, "#00D8FF"],
                                data: [this.dashboardTotalRegistration, this.dashboardTotalFTD],
                            },
                        ],
                        class: ["sample"],
                    },
                    {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: true,
                            text: "Conversions",
                            fontSize: 20,
                        },
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    }
                );
            } catch (error) {
                console.log(error.message, "error");
            }

            // this.loading = false;
        },
    };
</script>
