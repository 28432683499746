<script>
    import { Bar } from "vue-chartjs";

    export default {
        extends: Bar,
        mounted() {
            this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
            this.gradient2 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

            this.gradient.addColorStop(0, "rgba(13, 49, 83)");
            this.gradient2.addColorStop(0, "rgba(92, 157, 217)");

            this.renderChart(
                {
                    labels: ["Trades Volume", "Trades Number"],
                    datasets: [
                        {
                            type: "bar",
                            label: "Amount in Dollars",
                            backgroundColor: this.gradient,
                            data: [0, 0],
                        },
                        {
                            type: "bar",
                            label: "Count",
                            backgroundColor: this.gradient2,
                            data: [0, 0],
                        },
                    ],
                },
                {
                    title: {
                        display: true,
                        text: "Trades Volume & Trades Number",
                    },
                    scales: {
                        yAxes: [
                            {
                                stacked: true,
                            },
                        ],
                        xAxes: [
                            {
                                stacked: true,
                                categoryPercentage: 0.5,
                                barPercentage: 1,
                            },
                        ],
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            );
        },
    };
</script>
